/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { BuildingOffice2Icon, EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";

export default function Contact() {
  return (
    <div className="py-10 md:py-6 margin-0 padding-0 relative block pb-10 md:pb-20 appearance-none border-none text-gray-900 print:!table [&_td]:p-0">
      <div className=" relative mx-auto max-w-6xl gap-8 gap-y-5 px-4 md:px-8 lg:grid">
        <div className="relative -mx-4 mt-3 overflow-x-auto px-4 pb-2">
          <div className="heading-pre">Get in touch</div>
          <h1 className="heading-2xl ">Contact Information</h1>
          <div className="relative mx-auto flex   flex-col gap-8 px-4  lg:grid  ">
            <div className="max-w-xl lg:mx-0 lg:w-full">
              <p className="mt-6 text-md leading-8 text-gray-500">
                Please feel free to reach out to me with any questions or inquiries. I look forward
                to connecting with you. Don’t hesitate to contact me!
              </p>
              <dl className="mt-10 space-y-4 text-base leading-7 text-gray-500">
                <div className="flex gap-x-4">
                  <dt className="flex-none">
                    <span className="sr-only">Telephone</span>
                    <BuildingOffice2Icon className="h-7 w-6 text-gray-500" aria-hidden="true" />
                  </dt>
                  <dd>
                    Hamilton,Ontario
                    <br />
                  </dd>
                </div>
                <div className="flex gap-x-4">
                  <dt className="flex-none">
                    <span className="sr-only">Telephone</span>
                    <PhoneIcon className="h-7 w-6 text-gray-500" aria-hidden="true" />
                  </dt>
                  <dd>
                    <a className="hover:text-blue-700" href="tel:++1 (437) 677-7727">
                      +1 (437) 677-7727
                    </a>
                  </dd>
                </div>
                <div className="flex gap-x-4">
                  <dt className="flex-none">
                    <span className="sr-only">Telephone</span>
                    <EnvelopeIcon className="h-7 w-6 text-gray-500" aria-hidden="true" />
                  </dt>
                  <dd>
                    <a className="hover:text-blue-900" href="mailto:parbadid@mcmaster.ca">
                      parbadid@mcmaster.ca
                    </a>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
