import { ResumeSectionDateEvents } from "components/resume/resume-section-date-events";
import { useResumeSectionInView } from "components/resume/use-resume-section-in-view";
import { CV } from "content/cv";
import { FC, useEffect, useState } from "react";
 

export const WorkExperience: FC = (props) => {
  const { sections, filter, showSection, selectFilter } = useResumeSectionInView();
  const [inView, setInView] = useState("intro");

  useEffect(() => {
    for (const key in sections) {
      const val = sections[key];
      if (!val.showing) continue;

      if (val.centerVisible && val.fullyVisible) {
        setInView(key);
        break;
      }

      if (val.fullyVisible) {
        setInView(key);
        break;
      }

      if (val.centerVisible) {
        setInView(key);
        break;
      }
      setInView("");
    }
  }, [sections]);

  return (
    <table className="margin-0 padding-0 relative block appearance-none border-none text-gray-900 print:!table [&_td]:p-0">
      <div className="relative  mx-auto max-w-6xl gap-8 px-4 md:px-8 py-5 lg:grid xl:py-5">
        <div className="relative -mx-4 mt-3 overflow-x-auto px-4 pb-2">
          <div className="heading-pre">Experience</div>
          <h1 className="heading-2xl ">Work Experience</h1>
          <div className="spacing-8">
            {CV.experience
              .sort((a, b) => {
                if (new Date(a.dateFrom) < new Date(b.dateFrom)) return 1;
                if (new Date(a.dateFrom) > new Date(b.dateFrom)) return -1;
                return 0;
              })
              .filter(({ type }) => type.includes(filter) || filter === "all")
              .map(
                (
                  {
                    dateFrom,
                    dateTo,
                    city,
                    country,
                    title,
                    responsibilities,
                    company,
                    description,
                    type,
                  },
                  index,
                  arr
                ) => {
                  return (
                    <section className="relative mb-auto break-inside-avoid-page" key={index}>
                      <div className="relative flex">
                        {/* <ResumeSectionDateSidebar
                                  dateFrom={dateFrom}
                                  dateTo={dateTo}
                                  showDateRange
                                  isLast={index === arr.length - 1}
                                /> */}

                        <ResumeSectionDateEvents
                          name={title}
                          organization={company}
                          city={city}
                          country={country}
                          dateFrom={dateFrom}
                          dateTo={dateTo}
                          showDateRange
                          description={description}
                          responsibilities={responsibilities}
                        />
                      </div>
                    </section>
                  );
                }
              )}
          </div>
        </div>
      </div>
    </table>
  );
};

export default WorkExperience;
