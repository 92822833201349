import Contact from "components/contact";
import { Projects } from "components/projects";
import { Hero } from "components/sections/hero";
import Education from "content/Education";
import { WorkExperience } from "content/work-experience";
import party from "party-js";
import { FC } from "react";
import { components } from "twitter-api-sdk/dist/gen/openapi-types";
type IndexProps = {
  twitterData: components["schemas"]["User"];
};

party.settings.respectReducedMotion = false;

export const Index: FC<IndexProps> = (props) => {
  return (
    <>
      <Hero />
      <WorkExperience />
      <Education />
      <Contact />
    </>
  );
};
export default Index;
