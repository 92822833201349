import React, { useState, useEffect } from 'react';

const TypingAnimation = ({ textArray, typingSpeed = 150, pause = 1000 }) => {
  const [currentText, setCurrentText] = useState('');
  const [index, setIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    // If index exceeds the array length, reset it to loop
    if (index >= textArray.length) {
      setIndex(0);
    }

    let typingTimeout;

    if (!isDeleting && charIndex < textArray[index].length) {
      // Typing effect
      typingTimeout = setTimeout(() => {
        setCurrentText((prev) => prev + textArray[index].charAt(charIndex));
        setCharIndex((prev) => prev + 1);
      }, typingSpeed);
    } else if (isDeleting && charIndex > 0) {
      // Deleting effect
      typingTimeout = setTimeout(() => {
        setCurrentText((prev) => prev.slice(0, -1));
        setCharIndex((prev) => prev - 1);
      }, typingSpeed / 2);
    } else {
      // Toggle between typing and deleting, or move to the next text
      if (!isDeleting) {
        typingTimeout = setTimeout(() => setIsDeleting(true), pause);
      } else {
        setIsDeleting(false);
        setCharIndex(0);
        setIndex((prev) => (prev + 1) % textArray.length); // Loop to the start
      }
    }

    return () => clearTimeout(typingTimeout);
  }, [textArray, index, charIndex, isDeleting, typingSpeed, pause]);

  return (
    <h1 style={{ minHeight: '1em' }}>{currentText || '\u00A0'}</h1> // non-breaking space
  );
};

export default TypingAnimation;
