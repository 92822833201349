// @ts-ignore
import heroCode from "!!raw-loader!content/code-blocks/hero.txt"; // Adding `!!` to a request will disable all loaders specified in the configuration
import { SiNodeDotJs } from "@react-icons/all-files/si/SiNodeDotJs";
import { SiReact } from "@react-icons/all-files/si/SiReact";
import { SiShopify } from "@react-icons/all-files/si/SiShopify";
import { BiLogoSpringBoot } from "react-icons/bi";
import { SiTailwindcss } from "@react-icons/all-files/si/SiTailwindcss";
import { Link } from "components/link";
import { TECH } from "content/tech-stack";
import party from "party-js";

export const HERO = {
  pre: "Welcome to my portfolio site.",
  heading: (
    <>
      I'm <strong>Darshan </strong>
    </>
  ),
  roles : ['ML Engineer', 'Software Developer','Data Scientist','Fullstack developer'],
  tech: [
    TECH.nextjs,
    {
      name: "Node.js",
      Icon: ({ className }) => <SiNodeDotJs className={className} />,
    },
    {
      name: "Tailwind",
      Icon: ({ className }) => <SiTailwindcss className={className} />,
    },
    {
      name: "Spring boot",
      Icon: ({ className }) => <BiLogoSpringBoot className={className} />,
    },
  ] as const,
  body: (
    <>
      I create code that brings projects to life—building lightning-fast websites, streamlining processes through automated API integrations, developing custom tools, and crafting visually compelling user experiences. I'm always eager to dive into new tech, frameworks, and languages to keep elevating my skills.     {/* <em
        className="relative cursor-pointer before:absolute b:bottom-0 b:-z-10 b:h-3 b:w-full b:-rotate-2 b:animate-hint-hint b:bg-pink-400/70 b:blur-sm d:b:bg-pink-600"
        onClick={(e) => {
          e.currentTarget.classList.remove("before:absolute");
          party.confetti(e.currentTarget, { count: 40 });
        }}
      >
        WOW!
      </em> */}
      <span className="mt-4 block" /> Right now, I’m diving into Spark and Hadoop to deepen my expertise in big data solutions.
      {" "}
      {/* <Link target="_blank" href="https://astro.build/" className="underline hfa:text-sky-500"> */}
         
      {/* </Link>{" "} */}
       {" "}
      {/* <Link href="https://replicache.dev" target="_blank" className="underline hfa:text-sky-500"> */}
         
      {/* </Link> */}
      
    </>
  ),
  cta1: {
    href: "mailto:parbadid@mcmaster.ca",
    name: "Email",
  },
  cta2: {
    href: "https://drive.google.com/file/d/1KGNywI3BekYvQHpWCFjj9BPMqs7nLNA1/view?usp=sharing",
    name: "Resume",
  },
  code: `${heroCode}`,
};
